export default {
  api: {
    url: "https://docs.taycor.com/api/v1",
  },
  ai: {
    disabled: false,
    key: "f18c99d1-75f3-4ca8-b6f0-e35a5eb958ba",
  },
  storage: {
    url: "https://taycordocupload.blob.core.windows.net/files-pc8ac3cb1",
  },
};
