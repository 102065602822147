import React from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ai } from "../../Helpers/telemetry";
import { withRouter } from "react-router-dom";
import config from "../../config";

interface TelemetryProviderProps {
  instrumentationKey: string;
  history: any;
  after?: () => void;
}

interface TelemetryProviderState {
  initialized: boolean;
}

class TelemetryProvider extends React.Component<
  TelemetryProviderProps,
  TelemetryProviderState
> {
  constructor(props: TelemetryProviderProps) {
    super(props);
    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    if (config.ai.disabled) return;

    const { history } = this.props;
    const { initialized } = this.state;
    const AppInsightsInstrumentationKey = this.props.instrumentationKey;
    if (
      !Boolean(initialized) &&
      Boolean(AppInsightsInstrumentationKey) &&
      Boolean(history)
    ) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      this.setState({ initialized: true });
    }

    if (this.props.after) {
      this.props.after();
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(
  //@ts-ignore
  withAITracking(ai.reactPlugin, TelemetryProvider, "Main", "main")
);
