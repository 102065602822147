import moment from "moment";

export const sixHours = 6 * 60 * 60 * 1000;
export const thirtyMins = 30 * 60 * 1000;
export const twoWeeks = 2 * 7 * 24 * 60 * 60 * 1000;

const setPaddingToTimeComponent = (timeComponent: number) =>
  timeComponent < 10 ? `0${timeComponent}` : `${timeComponent}`;

export const getEtcLabel = (
  currentTime: number,
  uploadTime: number,
  offset: number
) => {
  const timeOfsett = offset - (currentTime - uploadTime);
  if (timeOfsett < 0 || timeOfsett > sixHours) return "COMPLETE";

  const duration = moment.duration(timeOfsett);
  return `ETC: ${setPaddingToTimeComponent(
    duration.hours()
  )} H ${setPaddingToTimeComponent(
    duration.minutes()
  )} M ${setPaddingToTimeComponent(duration.seconds())} S`;
};
