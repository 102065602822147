import Cookies from "js-cookie";
import { UploadInstance } from "./types";

export interface UploadHistoryData {
  time: number;
  email: string;
  phone: string;
}

export const saveUploadToHistory = (
  uploadInstance: UploadInstance,
  currentTime: number
) => {
  const data: UploadHistoryData = {
    time: currentTime,
    email: uploadInstance.repEmail,
    phone: uploadInstance.repPhone,
  };
  Cookies.set(`wut${uploadInstance.id}`, data, { expires: 14 /*days*/ });
  return data;
};

export const getUploadFromHistory = (uploadId: string): UploadHistoryData => {
  const existingUploadData = Cookies.getJSON(`wut${uploadId}`);
  return existingUploadData;
};
