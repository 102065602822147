import React, { useState, useEffect } from "react";
import { getUploadInstance } from "../../Api";
import logger from "../../Helpers/logger";
import { UploadInstance } from "../../Helpers/types";
import { Redirect, useParams } from "react-router-dom";
import Layout from "../Layout";
import { Upload } from "../Upload";
import { ThankYou } from "../ThankYou";
import {
  getUploadFromHistory,
  saveUploadToHistory,
  UploadHistoryData,
} from "../../Helpers/uploadHistory";

function UploadContainer() {
  const { uploadId } = useParams<{ uploadId: string }>();
  const [state, setState] = useState<{
    uploadInstance: UploadInstance | null;
    uploadNotFound: Boolean;
    existingUpload: UploadHistoryData | null;
  }>({
    uploadInstance: null,
    uploadNotFound: false,
    existingUpload: null,
  });

  useEffect(() => {
    const existingUpload = getUploadFromHistory(uploadId);
    if (existingUpload) {
      setState({
        uploadInstance: null,
        existingUpload: existingUpload,
        uploadNotFound: false,
      });
      return;
    }

    getUploadInstance(
      uploadId,
      (upload) => {
        setState({
          uploadInstance: upload,
          uploadNotFound: false,
          existingUpload: null,
        });
      },
      (err) => {
        logger.logError(err);
        setState({
          uploadInstance: null,
          uploadNotFound: true,
          existingUpload: null,
        });
      }
    );
  }, [uploadId]);

  const onUploadDone = (uploadId: string) => {
    if (!state.uploadInstance) return;

    const currentTime = new Date().getTime();
    const uploadHistoryData = saveUploadToHistory(
      state.uploadInstance,
      currentTime
    );
    setState({
      uploadInstance: null,
      uploadNotFound: false,
      existingUpload: uploadHistoryData,
    });
  };

  if (state.uploadNotFound) return <Redirect to="/"></Redirect>;

  return (
    <Layout
      phoneNumber={
        state.uploadInstance?.repPhone || state.existingUpload?.phone
      }
    >
      {state.existingUpload ? (
        <ThankYou uploadHistoryData={state.existingUpload}></ThankYou>
      ) : (
        <Upload
          uploadInstance={state.uploadInstance}
          onUploadDone={onUploadDone}
        ></Upload>
      )}
    </Layout>
  );
}

export default UploadContainer;
