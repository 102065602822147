import localConfig from "./Config/config.local";
import stagingConfig from "./Config/config.staging";
import prodConfig from "./Config/config.prod";

interface ConfigApi {
  url: string;
  fakeIt: boolean;
  uploadDocumentsCode: string;
  getUploadInstanceCode: string;
}
interface ConfigAppInsights {
  key?: string;
  disabled?: boolean;
}
interface ConfigStorage {
  url: string;
}
interface ConfigUpload {
  filesPerDocument: number;
}
interface Config {
  api: ConfigApi;
  ai: ConfigAppInsights;
  storage: ConfigStorage;
  upload: ConfigUpload;
}

const envConfig = (() => {
  const currentEnv = process.env.REACT_APP_ENVIRONMENT || "local";
  switch (currentEnv.toLowerCase()) {
    case "staging":
      return stagingConfig;
    case "prod":
      return prodConfig;

    default:
      return localConfig;
  }
})() as Partial<Config>;

const config: Config = {
  api: {
    ...{
      uploadDocumentsCode: process.env.REACT_APP_UPLOAD_DOCUMENTS_CODE,
      getUploadInstanceCode: process.env.REACT_APP_UPLOAD_INSTANCE_CODE,
      fakeIt: false,
    },
    ...envConfig.api,
  },
  ai: {
    ...{
      disabled: true,
      key: "<no-key>",
    },
    ...envConfig.ai,
  },
  storage: {
    ...{
      url: "https://taycordocupload.blob.core.windows.net/files-staging",
    },
    ...envConfig.storage,
  },
  upload: {
    ...{
      filesPerDocument: 10,
    },
    ...envConfig.upload,
  },
} as Config;

export default config;
