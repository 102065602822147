import { createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    colors: {
      lightBlue: string;
      darkBlue: string;
      lightGrey: string;
      darkGrey: string;
    };
    layout: {
      pageWidth: number;
    };
  }
}

const theme = createMuiTheme(
  {
    palette: {
      info: {
        light: "#1bcace",
        main: "#14679F",
      },
      primary: {
        main: "#1bcace",
        dark: "#14679F",
      },
      secondary: {
        light: "#fdecea",
        main: "#fa2f55",
        dark: "#611a15",
      },
      background: {
        default: "#fff",
      },
      text: {
        primary: "#333333",
        secondary: "#969899",
      },
    },
    typography: {
      fontFamily: "Montserrat",
      h1: {
        fontFamily: "Chonburi",
        fontSize: 25,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2.88,
        letterSpacing: "normal",
      },
      h2: {
        fontSize: 22,
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.23,
        letterSpacing: "normal",
      },
      h3: {
        fontSize: 20,
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2.5,
        letterSpacing: "normal",
      },
      h4: {
        fontSize: 18,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.22,
        letterSpacing: "normal",
      },
      h5: {
        fontSize: 17,
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.18,
        letterSpacing: "normal",
      },
      h6: {
        fontSize: 16,
        fontWeight: 300,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
      },
      subtitle1: {
        fontSize: 14,
        fontWeight: 300,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.57,
        letterSpacing: "normal",
      },
      subtitle2: {
        fontSize: 13,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.69,
        letterSpacing: "normal",
      },
    },
    spacing: 4,
    shape: {
      borderRadius: 5,
    },
  },
  {
    colors: {
      lightBlue: "#1bcace",
      darkBlue: "#14679F",
      lightGrey: "#F4F7FC",
      darkGrey: "#e8e7e7",
    },
    layout: {
      pageWidth: 840,
    },
  }
);

export const formField = {
  "& .MuiInputBase-root": {
    fontSize: 15,
    fontWeight: "normal",
    color: theme.palette.text.secondary,
  },
  "& .MuiFormLabel-root": {
    fontSize: 14,
    fontWeight: "normal",
    "&.Mui-focused": {
      color: theme.palette.primary.dark,
    },
    "&.Mui-error": {
      color: theme.palette.secondary.main,
    },
  },
  "& .MuiInput-underline": {
    "&:before": {
      borderBottom: `3px solid ${theme.colors.lightGrey}`,
    },
    "&:not(.Mui-error):after": {
      borderBottom: "none",
    },
    "&:hover": {
      "&:before": {
        borderBottom: `4px solid ${theme.colors.lightGrey}`,
      },
      "&.Mui-error:before": {
        borderBottom: `3px solid ${theme.colors.lightGrey}`,
      },
    },
    "&.Mui-focused": {
      "&:before": {
        borderBottom: `4px solid ${theme.colors.lightGrey}`,
      },
      "&.Mui-error:before": {
        borderBottom: `3px solid ${theme.colors.lightGrey}`,
      },
    },
  },
};

export default theme;
