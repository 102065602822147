import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const cardsBreakpoint = 580;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: theme.layout.pageWidth,
      maxWidth: theme.layout.pageWidth,
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        width: "100vw",
        padding: theme.spacing(0, 2),
      },
    },
    title: {
      color: theme.colors.darkBlue,
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
    subtitle: {
      color: "#848484",
      textAlign: "center",
      marginBottom: 19,
    },
    subtitleSecond: {
      marginTop: 40,
      marginBottom: 0,
      fontWeight: 500,
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        marginTop: 34,
      },
    },
    cards: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down(cardsBreakpoint)]: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      "& > div:nth-of-type(1)": {
        [theme.breakpoints.down(theme.layout.pageWidth)]: {
          display: "none",
        },
      },
    },
    card: {
      height: 340,
      width: 271,
      boxShadow: "0 6px 20px 0 rgba(143, 163, 178, 0.5)",
      borderRadius: 5,
      margin: 7,
      [theme.breakpoints.down(cardsBreakpoint)]: {
        display: "flex",
        flexDirection: "row",
        width: 343,
        height: 136,
      },
      [theme.breakpoints.down(350)]: {
        width: "100%",
        marginLeft: 12,
      },
    },
    cardTop: {
      height: 159,
      backgroundColor: "#eef1f3",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      boxShadow: "0 6px 20px 0 rgba(143, 163, 178, 0.5)",
      [theme.breakpoints.down(cardsBreakpoint)]: {
        width: 105,
        height: 136,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 5,
      },
      [theme.breakpoints.down(350)]: {
        width: "25%",
      },
      "& img": {
        height: 126,
        [theme.breakpoints.down(cardsBreakpoint)]: {
          maxWidth: 70,
          maxHeight: 70,
        },
      },
    },
    cardBottom: {
      height: 181,
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",
      [theme.breakpoints.down(cardsBreakpoint)]: {
        height: 136,
        width: 238,
      },
      "& div": {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 23,
        [theme.breakpoints.down(cardsBreakpoint)]: {
          marginTop: theme.spacing(2),
        },
        "& svg": {
          marginRight: theme.spacing(2),
        },
      },
      "& h6": {
        fontWeight: 300,
        color: "#848484",
        marginTop: 8,
        height: 56,
        textAlign: "center",
        padding: theme.spacing(0, theme.spacing(1)),
        [theme.breakpoints.down(cardsBreakpoint)]: {
          marginTop: 0,
          fontSize: 12,
          height: 78,
          display: "flex",
          alignItems: "center",
        },
      },
      "& > h5": {
        fontWeight: "normal",
        color: theme.colors.darkBlue,
        marginTop: 33,
        [theme.breakpoints.down(cardsBreakpoint)]: {
          marginTop: 0,
        },
      },
    },
    cardDone: {
      "& > div > img": {
        opacity: 0.4,
      },
      "& > div > div > h5, svg": {
        color: "#3fe0c5",
      },
    },
    cardActive: {
      backgroundColor: "#f7f9fd",
      "& > div:nth-of-type(1)": {
        backgroundColor: "#dbf2ff",
      },
      "& > div > div > h5, svg": {
        color: theme.colors.darkBlue,
      },
    },
    cardInactive: {
      "& > div > img": {
        opacity: 0.4,
      },
      "& > div > div > h5, svg": {
        color: "#848484",
      },
    },
    etcCountdown: {
      width: 170,
    },
    contactCards: {
      display: "flex",
      flexDirection: "row",
      marginTop: 25,
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        flexDirection: "column",
      },
    },
    contactCard: {
      height: 113,
      width: 414,
      paddingLeft: 92,
      display: "flex",
      flexDirection: "column",
      borderRadius: theme.spacing(1),
      margin: 8,
      justifyContent: "center",
      alignItems: "center",
      backgroundPositionX: "left",
      backgroundSize: "cover",
      [theme.breakpoints.down(446)]: {
        width: "100%",
        margin: theme.spacing(2, 0),
      },
      "& h5, h6": {
        color: "#848484",
      },
      "& h5:nth-of-type(1)": {
        color: theme.colors.darkBlue,
      },
      "& a": {
        textDecoration: "none",
        color: "#848484",
      },
    },
    details: {
      marginTop: 76,
      width: 546,
      color: "#969899",
      borderBottom: "solid 0.5px #707070",
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        marginTop: 28,
        width: "100%",
      },
      "& .MuiAccordion-root": {
        boxShadow: "none",
      },
      "& h2": {
        color: "#969899",
      },
      "&:hover": {
        borderBottom: `solid 1px ${theme.colors.darkBlue}`,
        "& svg": {
          color: theme.colors.darkBlue,
        },
      },
    },
    detailsText: {
      width: 546,
      color: "#848484",
      "& a": {
        color: "#848484",
        textDecoration: "none",
      },
    },
    detailsBoldText: {
      fontWeight: 600,
    },
    detailsItalicText: {
      fontStyle: "italic",
    },
  })
);

export default useStyles;
