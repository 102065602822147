import { get, postWithFiles } from "./fetch";

const getUploadInstance = (
  id: string,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get(`/upload/${id}`, success, error);
};

const uploadFiles = (
  id: string,
  data: any,
  files: any,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  postWithFiles(`/upload/${id}`, data, files, success, error);
};

export { getUploadInstance, uploadFiles };
