import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import classnames from "classnames";
import useStyles from "./thankyou.style";
import UploadStatus from "./children/UploadStatus";
import { UploadHistoryData } from "../../Helpers/uploadHistory";
import { useScrollIntoViewRef } from "../../Helpers/hooks/useScrollIntoViewRef";

interface ThankYouProps {
  uploadHistoryData: UploadHistoryData;
}

function ThankYou(props: ThankYouProps) {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const renderContactCard = (
    contact: string,
    contactType: string,
    hours: string,
    description: string,
    image: string
  ) => (
    <div
      className={classes.contactCard}
      style={{ backgroundImage: `url(${image})` }}
    >
      <Typography variant="subtitle2">{description}</Typography>
      <Typography variant="h5">{hours}</Typography>
      <Typography variant="h5">
        <a href={`${contactType}:${contact}`}>{contact}</a>
      </Typography>
    </div>
  );

  const detailsTextRef = useScrollIntoViewRef(showDetails);

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        Thank You!
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        We’ve received your submission. Here’s what’s next:
      </Typography>
      <UploadStatus uploadTime={props.uploadHistoryData.time}></UploadStatus>
      <Typography
        variant="subtitle1"
        className={classnames(classes.subtitle, classes.subtitleSecond)}
      >
        Questions? Below is your primary point of contact’s phone number &
        email.
      </Typography>
      <div className={classes.contactCards}>
        {renderContactCard(
          props.uploadHistoryData.phone || "310-730-7626",
          "tel",
          "M-F 10 AM to 7 PM ET",
          "Or Speak to a Finance Professional",
          "/phone-contact-card.svg"
        )}
        {renderContactCard(
          props.uploadHistoryData.email || "khart@taycor.com",
          "mailto",
          "ANYTIME - DAY or NIGHT",
          "Email a Finance Professional",
          "/email-contact-card.svg"
        )}
      </div>
      <div className={classes.details}>
        <Accordion
          expanded={showDetails}
          onChange={() => {
            setShowDetails(!showDetails);
          }}
          ref={detailsTextRef}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h2">We’re here to help!</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.detailsText}>
              <p>
                <Typography variant="h6" component="span">
                  We are committed to your success. If you need anything beyond
                  the support you’re already receiving or if we can improve in
                  anyway, don’t hesitate to reach out to Karlie Hart at{" "}
                  <a href="mailto:khart@taycor.com">
                    <span className={classes.detailsBoldText}>
                      khart@taycor.com
                    </span>
                  </a>
                  . We’ll do our best to respond to your email within the next
                  business day. We hope to be earning your business at every
                  step of the lending process & value your feedback!
                </Typography>
              </p>
              <p>
                <Typography variant="h6" component="span">
                  <span className={classes.detailsItalicText}>
                    Wondering what{" "}
                    <span className={classes.detailsBoldText}>ETC</span> means?
                  </span>{" "}
                  ETC stands for{" "}
                  <span className={classes.detailsBoldText}>
                    “Estimated Time of Completion”
                  </span>{" "}
                  and it is calculated by taking into consideration the current
                  queue of active finance files and the resources available to
                  process them. We will always endeavor to complete a task prior
                  to this ETC and feel free to reach out if we’ve missed the
                  mark!
                </Typography>
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default ThankYou;
