import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { CheckCircleOutlined, AccessTime, Update } from "@material-ui/icons";
import classnames from "classnames";
import { sixHours, thirtyMins, getEtcLabel } from "../../../Helpers/time";
import useStyles from "../thankyou.style";

type CardState = "done" | "active" | "inactive";
interface Card {
  text: string;
  avatar: string;
  state: CardState;
  etc: string;
}

interface UploadStatusProps {
  uploadTime: number;
}

function UploadStatus(props: UploadStatusProps) {
  const classes = useStyles();
  const [currentTime, setCurrentTime] = useState<number>(new Date().getTime());

  useEffect(() => {
    // re-render every 1 second
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const cardStateLabel = {
    done: "Done!",
    active: "Processing...",
    inactive: "In a few hours...",
  };
  const cardStateIcon = {
    done: <CheckCircleOutlined></CheckCircleOutlined>,
    active: <Update></Update>,
    inactive: <AccessTime></AccessTime>,
  };
  const cardStateClass = {
    done: classes.cardDone,
    active: classes.cardActive,
    inactive: classes.cardInactive,
  };

  const renderCard = (card: Card, index: number) => (
    <div
      className={classnames(classes.card, cardStateClass[card.state])}
      key={index}
    >
      <div className={classes.cardTop}>
        <img src={card.avatar} alt="avatar"></img>
      </div>
      <div className={classes.cardBottom}>
        <div>
          {cardStateIcon[card.state]}
          <Typography variant="h5">{cardStateLabel[card.state]}</Typography>
        </div>
        <Typography variant="subtitle2">{card.text}</Typography>
        <Typography
          variant="h5"
          className={classnames({
            [classes.etcCountdown]: card.etc !== "COMPLETE",
          })}
        >
          {card.etc}
        </Typography>
      </div>
    </div>
  );

  const thirtyMinsHavePassed = currentTime - props.uploadTime > thirtyMins;
  const sixHoursHavePassed = currentTime - props.uploadTime > sixHours;

  const cards: Array<Card> = [
    {
      text: "Application and documents received",
      avatar: "/documents-received.svg",
      state: "done",
      etc: "COMPLETE",
    },
    {
      text: "We'll run a soft credit check and continue processing.",
      avatar: "/soft-credit-check.svg",
      state: thirtyMinsHavePassed ? "done" : "active",
      etc: thirtyMinsHavePassed
        ? "COMPLETE"
        : getEtcLabel(currentTime, props.uploadTime, thirtyMins),
    },
    {
      text:
        "Your dedicated Taycor contact will call you to finish the underwriting process. Typically, this will happen within 1 business day.",
      avatar: "/application-finish.svg",
      state: !thirtyMinsHavePassed
        ? "inactive"
        : sixHoursHavePassed
        ? "done"
        : "active",
      etc: getEtcLabel(currentTime, props.uploadTime, sixHours),
    },
  ];

  return (
    <div className={classes.cards}>
      {cards.map((card, index) => renderCard(card, index))}
    </div>
  );
}

export default UploadStatus;
