import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline, Typography } from "@material-ui/core";
import "./App.css";
import { UploadContainer } from "./Components/UploadContainer";
import theme from "./Theme";
import Layout from "./Components/Layout";
import { TelemetryProvider } from "./Components/Telemetry";
import config from "./config";

function App() {
  return (
    <Router>
      {/* @ts-ignore */}
      <TelemetryProvider instrumentationKey={config.ai.key}>
        <ThemeProvider theme={theme}>
          <CssBaseline></CssBaseline>
          <Switch>
            <Route
              path="/upload/:uploadId"
              render={({ match: { url } }: any) => {
                return <UploadContainer></UploadContainer>;
              }}
            ></Route>
            <Route path="/">
              <Layout>
                <Typography variant="h1">Page Not Found!</Typography>
              </Layout>
            </Route>
          </Switch>
        </ThemeProvider>
      </TelemetryProvider>
    </Router>
  );
}

export default App;
