import React, { useState } from "react";
import useStyles from "./layout.style";
import "./header.style.css";

interface LayoutProps {
  phoneNumber?: string;
  children?: any;
}

function Layout(props: LayoutProps) {
  const classes = useStyles();

  const [headerContactOpen, setHeaderContactOpen] = useState<boolean>(false);
  const phoneNumber = props.phoneNumber || "800-747-9335";

  return (
    <div className={classes.root}>
      <header className="site-header d-flex">
        <div className="container-fluid">
          <div className="row">
            <div className="col brand">
              <img
                src="/taycor-logo-2016-clear-1024x185.png"
                alt="Logo Taycor"
              />
            </div>
            <div className="col contact d-flex">
              <div className={`logo-taycor ${headerContactOpen ? "open" : ""}`}>
                <img
                  src="/powered-by-taycor.png"
                  alt="Taycor"
                  className="img-1"
                />
                <img
                  src="/powered-by-taycor-small.png"
                  alt="Taycor"
                  className="img-2"
                />
              </div>
              <div
                className={`finance-questions d-flex ${
                  headerContactOpen ? "open" : ""
                }`}
              >
                <div
                  className={`trigger-contact d-flex ${
                    headerContactOpen ? "open" : ""
                  }`}
                  onClick={() => {
                    setHeaderContactOpen(!headerContactOpen);
                  }}
                >
                  <i className="icon-phone"></i>
                  <i className="icon-collapse"></i>
                </div>
                <p>
                  <span>Questions?</span>
                </p>
                <a className="d-block" href={`tel:${phoneNumber}`}>
                  <span className="phone">{phoneNumber}</span>
                  <br />
                  <span className="business-hours">M-F 10 AM to 7 PM ET</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
}

export default Layout;
