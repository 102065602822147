import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { formField } from "../../Theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: theme.layout.pageWidth,
      maxWidth: theme.layout.pageWidth,
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        width: "100vw",
        padding: theme.spacing(0, 2),
      },
    },
    tabs: {
      marginTop: theme.spacing(1),
      width: 834,
      maxWidth: 834,
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        width: "100%",
      },
      "& .MuiTab-root": {
        borderBottom: `6px solid ${theme.colors.darkGrey}`,
        "& span": {
          ...theme.typography.h2,
          textTransform: "capitalize",
        },
        "&.Mui-selected": {
          "& span": {
            fontWeight: "bold",
            color: theme.colors.darkBlue,
          },
        },
      },
      "& .MuiTabs-indicator": {
        height: 6,
        backgroundColor: theme.colors.lightBlue,
      },
      "& .MuiTabs-scroller": {
        display: "flex",
        justifyContent: "center",
      },
    },
    tabPanel: {},
    title: {
      color: theme.colors.darkBlue,
      textAlign: "center",
      marginTop: theme.spacing(2),
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        marginTop: 5,
      },
    },
    subtitle: {
      color: "#848484",
      textAlign: "center",
      marginBottom: 19,
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        marginBottom: 13,
      },
    },
    documentLines: {
      width: "100%",
    },
    documentLine: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      marginTop: 16,
      color: "#838383",
      width: "100%",
    },
    documentIndicator: {
      backgroundColor: theme.colors.darkBlue,
      height: 10,
      width: 10,
      minWidth: 10,
      borderRadius: 5,
      marginRight: 12,
      marginBottom: 2,
    },
    documentText: {
      maxWidth: "95%",
    },
    form: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    dropzoneContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: 48,
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        marginTop: 34,
      },
      "& .MuiTypography-subtitle1": {
        fontWeight: "bold",
        marginTop: 41,
        color: "#848484",
        [theme.breakpoints.down(theme.layout.pageWidth)]: {
          marginTop: 23,
        },
      },
    },
    dropzoneRoot: {
      backgroundColor: theme.colors.lightGrey,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "unset",
      height: 158,
      "&:focus": {
        outline: "none",
      },
    },
    dropzoneRootColumn: {
      flexDirection: "column",
    },
    dropzoneText: {
      margin: theme.spacing(3),
    },
    dropzoneFilePreview: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        width: "100%",
        margin: 0,
      },
    },
    dropzoneFilePreviewItem: {
      "&.MuiGrid-item": {
        [theme.breakpoints.down(theme.layout.pageWidth)]: {
          padding: "7px 0",
        },
      },
      "& > div": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        "& > div": {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          "& > .MuiChip-root": {
            width: 355,
            height: 43,
            borderRadius: 22,
            backgroundColor: "#8ee4d5",
            display: "flex",
            justifyContent: "start",
            [theme.breakpoints.down(theme.layout.pageWidth)]: {
              marginLeft: 66,
              maxWidth: "calc(100% - 66px)",
            },
            "& .MuiChip-label": {
              width: "100%",
              textAlign: "center",
              color: "#4e4e4e",
              fontWeight: 300,
            },
            "& svg": {
              marginLeft: 13,
              color: "#79c8c9",
            },
          },
        },
      },
      "& .MuiDropzonePreviewList-removeButton": {
        top: 16,
        right: 448,
        width: 44,
        height: 44,
        color: theme.palette.secondary.main,
        opacity: 1,
        boxShadow: "none",
        [theme.breakpoints.down(theme.layout.pageWidth)]: {
          top: 7,
          left: 4,
        },
      },
    },
    dropzonePreviewSelect: {
      ...formField,
      width: 329,
      height: 43,
      marginLeft: 4,
      "&:hover": {
        "& .MuiSelect-icon": {
          color: theme.colors.darkBlue,
        },
      },
    },
    dropzoneDisabled: {
      pointerEvents: "none",
      cursor: "default",
    },
    fileTypeText: {
      color: "#222",
      fontWeight: 600,
      width: 88,
      marginBottom: 8,
      textAlign: "left",
      whiteSpace: "nowrap",
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        marginLeft: 0,
      },
    },
    button: {
      width: 407,
      height: "5em",
      minHeight: 36,
      borderRadius: 22,
      color: theme.palette.common.white,
      textTransform: "capitalize",
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        width: 343,
      },
      [theme.breakpoints.down(360)]: {
        width: "100%",
      },
    },
    details: {
      marginTop: 76,
      width: 546,
      maxWidth: 546,
      color: "#969899",
      borderBottom: "solid 0.5px #707070",
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        width: "100%",
        marginTop: 30,
      },
      "& .MuiAccordion-root": {
        boxShadow: "none",
      },
      "& h2": {
        color: "#969899",
      },
      "&:hover": {
        borderBottom: `solid 1px ${theme.colors.darkBlue}`,
        "& svg": {
          color: theme.colors.darkBlue,
        },
      },
    },
    detailsText: {
      width: 546,
      maxWidth: 546,
      color: "#848484",
      [theme.breakpoints.down(theme.layout.pageWidth)]: {
        width: "100%",
      },
      "& a": {
        color: "#848484",
        textDecoration: "none",
      },
    },
    detailsBoldText: {
      fontWeight: 600,
    },
    detailsItalicText: {
      fontStyle: "italic",
    },
    errors: {
      color: theme.palette.secondary.main,
    },
    backdrop: {
      display: "flex",
      flexDirection: "column",
      zIndex: 10000,
      color: theme.palette.common.white,
    },
  })
);

export default useStyles;
